import React, { useEffect } from "react";
import Header from "./Header";
import MainApplication from "./MainApplication";
import { useNavigate } from "react-router-dom";
import LoadingAnimation from "./LoadingAnimation";
import { useApp } from "../../../Context/Context";
import Alert from "./Alert";

const CompleteMainApp = () => {
  const { animation,alertType,alertMessage ,alertVisible,setAlertVisible} = useApp();
  const navigate = useNavigate();
  useEffect(() => {
    if (!sessionStorage.getItem("user-data")) {
      navigate("/");
    }
  }, [navigate]);



  return (
    <>
    {
        <Alert
          type={alertType}
          message={alertMessage}
          visible={alertVisible}
          onClose={() => setAlertVisible(false)}
        />
      }
      {animation && <LoadingAnimation />}
      <Header />
      <MainApplication />
    </>
  );
};

export default CompleteMainApp;
