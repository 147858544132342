import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "../HomePage/Home";
import { Container } from "react-bootstrap";
import "./common.css";
import AllotRoom from "../AllotRoom/AllotRoom";
import { useApp } from "../../../Context/Context";
import MajorModal from "./MajorModal";
import ReceiveItems from "../RecieveItem/ReceiveItems";
import ItemsMainPage from "../Worker/ItemsMainPage";
import Apartments from "../Apartments/Apartments";
import RoomsMain from "../RoomsPage/RoomsMain";
import ItemsRoomHave from "../RoomsPage/ItemsRoomHave";
import Profile from "./Profile";
import RecieveditemsPage from "../Worker/RecieveditemsPage";
import WorkerMainFlow from "../RoomsPage/WorkerMainFlow";
import DefectedMissingItems from "../DefectedMissItems/DefectedMissingItems";
import ClenanerWorkFlow from "../RoomsPage/ClenanerWorkFlow";

const MainApplication = () => {

  const navigate = useNavigate();

  useEffect(()=>{
    if(!sessionStorage.getItem("user-data")){
      navigate("/")
    }
    
  
  },[navigate])

  const { modalActive, isAdmin,setIsAdmin ,isCleaner,setIsCleaner} = useApp();


  useEffect(()=>{

    setIsAdmin((JSON.parse(sessionStorage.getItem("user-data")).user.role === "Admin"));
    setIsCleaner((JSON.parse(sessionStorage.getItem("user-data")).user.role === "Cleaner"))
  },[])
  return (
    <>
      <Container fluid="xxl" style={{ flexGrow: "1" }} className="mainApp">
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/home/profile" element={<Profile />} />
          <Route path="/home/allot-items" element={<AllotRoom />} />
          <Route path="/home/receive-items" element={<ReceiveItems />} />
          <Route
            path="/home/allot-items/:workerName"
            element={<ItemsMainPage />}
          />
          <Route
            path="/home/receive-items/:workerName"
            element={<ItemsMainPage />}
          />
          <Route path="/home/received-items/" element={<RecieveditemsPage />} />
          {/* <Route
            path="/home/receive-items/:workerName"
            element={<ItemsMainPage />}
          /> */}
          <Route path="/home/apartments" element={<Apartments />} />
          <Route path="/home/defected-missing-items" element={<DefectedMissingItems />} />
          <Route
            path="/home/apartments/:apartmentName"
            element={<RoomsMain />}
          />
          {isAdmin ? (
            <Route
              path="/home/apartments/:apartmentName/:roomId"
              element={<ItemsRoomHave />}
            />
          ) : (
            isCleaner ? 
            
            <Route
              path="/home/apartments/:apartmentName/:roomId"
              element={<ClenanerWorkFlow />}
            />:<Route
            path="/home/apartments/:apartmentName/:roomId"
            element={<WorkerMainFlow />}
          />
          )}
        </Routes>
      </Container>
      {modalActive && <MajorModal />}
    </>
  );
};

export default MainApplication;
