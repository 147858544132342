import React from "react";
import editICon from "./Icons/editICon.svg";
import { useApp } from "../../../Context/Context";
import EditItemModal from "./EditItemModal";

import dummyImg from "./Icons/dummy.jpeg";

const ItemCard = ({ item,editable }) => {
  const { setModalActive ,MEDIA_ENDPOINT} = useApp();

  return (
    <>
      <div className="itemCardMainDIv">
        {item.item_photo ? <img alt="itemPic" src={`${MEDIA_ENDPOINT}${item.item_photo}`} /> : <img alt="itemPic" src={dummyImg} />}
        <div className="itemsDetails">
          <p>
            {item.item_name} <span>({item.quantity || item.quantity_allotted || 0 })</span>
          </p>
          {editable && <button
            onClick={() => {
              setModalActive(<EditItemModal item={item} />);
            }}
          >
            Edit
            <img alt="editIcon" src={editICon} />
          </button>}
        </div>
      </div>
    </>
  );
};

export default ItemCard;
