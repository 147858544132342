import React, { useEffect } from "react";

const Alert = ({ type = "info", message, visible, onClose }) => {
  useEffect(() => {
    if (visible) {
      const timer = setTimeout(() => {
        onClose();
      }, 2000); // Automatically hide after 5 seconds
      return () => clearTimeout(timer);
    }
  }, [visible, onClose]);

  if (!visible) return null;

  const containerStyle = {
    position: "fixed",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 1000,
    animation: "slide-up 0.5s ease-out",
  };

  const typeColors = {
    danger: { background: "#f8d7da", color: "#721c24", border: "#f5c6cb" },
    info: { background: "#d1ecf1", color: "#0c5460", border: "#bee5eb" },
    success: { background: "#d4edda", color: "#155724", border: "#c3e6cb" },
  };

  const messageStyle = {
    backgroundColor: typeColors[type]?.background || "#d1ecf1",
    color: typeColors[type]?.color || "#0c5460",
    border: `1px solid ${typeColors[type]?.border || "#bee5eb"}`,
    padding: "10px 20px",
    borderRadius: "5px",
    fontSize: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    maxWidth: "90%",
    textAlign: "center",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  };

  const buttonStyle = {
    background: "none",
    border: "none",
    color: "inherit",
    fontSize: "1.5rem",
    marginLeft: "10px",
    cursor: "pointer",
  };

  return (
    <div style={containerStyle}>
      <div style={messageStyle}>
        {message}
        <button style={buttonStyle} onClick={onClose}>
          &times;
        </button>
      </div>
    </div>
  );
};

export default Alert;
