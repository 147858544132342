import React, { useEffect } from "react";
import arrow from "../../CommonIcons/itemArr.svg";
import "../../commonCss/card.css";
import WorkerCard from "./WorkerCard";
import "./allotrooms.css"
import { useApp } from "../../../Context/Context";

const AllotRoom = () => {
  const {fetchWorkers,workers } = useApp();

  useEffect(()=>{
    fetchWorkers()
    // eslint-disable-next-line
  },[])

  return (
    <>
      <div className=" majorDisplayMainDiv homeItemsMainDiv ">
        <h2>
          Select Worker <img alt="arrow" src={arrow} />
        </h2>
        <div className="cardsContiner">
          
          <>
          {workers.map((worker) => <WorkerCard worker = {worker} key={worker.user_id}/>)}
          </>
        </div>
      </div>
    </>
  );
};

export default AllotRoom;
