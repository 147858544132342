import React, { useEffect } from "react";
import { useApp } from "../../../Context/Context";

const MajorModal = () => {
  const { modalActive, setModalActive } = useApp();

  useEffect(() => {
    const handleClickOutside = (event) => {
      const modal = document.querySelector(".modalContent");
      if (modal && !modal.contains(event.target)) {
        setModalActive(null)
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="modalOverlay">
        <div className="modalContent">
          {modalActive}
        </div>
      </div>
    </>
  );
};

export default MajorModal;
