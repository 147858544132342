import React from "react";
import imageLink from "./Icons/dummy.jpeg";
import checked from "../../CommonIcons/checked.svg";
import unckecked from "../../CommonIcons/unchecked.svg";
import { useApp } from "../../../Context/Context";

const SelectItemsCard = ({ items1, setItems1, item }) => {
  const { MEDIA_ENDPOINT } = useApp();

  const handleCheckboxChange = (id) => {
    setItems1(
      items1.map((item) =>
        item.item_id === id ? { ...item, selected: !item.selected } : item
      )
    );
  };

  // Handle input value change
  const handleInputChange1 = (id, newValue) => {
    setItems1(
      items1.map((item) =>
        item.item_id === id
          ? {
              ...item,
              value:
                item.quantity < newValue
                  ? item.quantity
                  : newValue <= 0
                  ? 1
                  : newValue,
            }
          : item
      )
    );
  };

  const handleInputChange2 = (id, newValue) => {
    setItems1(
      items1.map((item) =>
        item.item_id === id
          ? {
              ...item,
              value:
                item.quantity_allotted < newValue
                  ? item.quantity_allotted
                  : newValue <= 0
                  ? 1
                  : newValue,
            }
          : item
      )
    );
  };
  console.log(items1)

  return (
    <div>
      <li key={item.id} className="cardMainDIv selectedItemMain">
        <div className="checkedUncked">
          {item.selected ? <img alt="checked" src={checked} /> : <img alt="uncheked" src={unckecked} />}
        </div>

        <img
          alt="selectedItemPic"
          src={
            item.item_photo ? `${MEDIA_ENDPOINT}${item.item_photo}` : imageLink
          }
          onClick={() => {
            handleCheckboxChange(item.item_id);
          }}
        />
        <div className="itemsDetails">
          <p>
            {item.item_name}{" "}
            <span>({item.quantity || item.quantity_allotted})</span>
          </p>
          {item.selected && (
            <input
              className="selectedItemInput"
              type="Number"
              value={item.value}
              max={item.quantity || item.quantity_allotted}
              min={1}
              onChange={(e) => {
                if (item.quantity) {
                  handleInputChange1(item.item_id, e.target.value);
                } else {
                  handleInputChange2(item.item_id, e.target.value);
                }
              }}
            />
          )}
        </div>
      </li>
    </div>
  );
};

export default SelectItemsCard;
