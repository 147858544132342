


import React, { useState } from "react";
import { useApp } from "../../../Context/Context";

const EditItemModal = ({item}) => {
  // Define state for the inputs
  const {  setModalActive ,API_ENDPOINT,setItems,setAnimation,showAlert} = useApp();
  const [name, setName] = useState(item.item_name);
  const [quantity, setQuantity] = useState(item.quantity);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("No file chosen");

  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    } else {
      setFile(null);
      setFileName("No file chosen");
    }
  };

  const handleSubmit = () => {
    // Create a form data object for multipart/form-data
    
    const formData = new FormData();
    formData.append("item_name", name);
    formData.append("quantity", quantity);
    if(file)formData.append("item_photo", file);
  
    // Simulate API call (replace it with actual API request)
    setAnimation(true)
    fetch(`${API_ENDPOINT}/owner/edit_item/${item.item_id}`, {
      method: "PATCH",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        
        // Update the items state with the modified item
        setItems((prevItems) =>
          prevItems.map((prevItem) =>
            prevItem.item_id === item.item_id
              ? { ...prevItem, name: data.item_name, quantity: data.quantity, item_photo: data.item_photo }
              : prevItem
          )
        );
        setAnimation(false)
        showAlert("success", "Items Edit Successfully !! ")
      })
      .catch((error) => { 
        setAnimation(false)
        showAlert("danger", "Items Edit failed !! ")
        console.error("Error:", error);
      })
     
    setModalActive(null);  // Close the modal
  };
  

  

  return (
    <>
      <div className="modalContentInner">
        <h3>Edit Item</h3>
        <div className="inputContainer">
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="number"
            placeholder="Quantity"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
          />
          <div className="file-input-container">
            <input
              type="file"
              id="file"
              className="file-input"
              onChange={handleFileChange}
            />
            <label htmlFor="file" className="file-label">
              Choose File
            </label>
            <span className="file-name">{fileName}</span>
          </div>
        </div>
        <button className="modalBtn" onClick={handleSubmit}>
          Edit
        </button>
      </div>
    </>
  );
};

export default EditItemModal;
