import React, { useEffect } from "react";
import { useApp } from "../../../Context/Context";

const LoadingAnimation = () => {
  const { animation } = useApp();

  console.log("Animation state:", animation);
  
  useEffect(() => {
    const canvas = document.getElementById("myCanvas");
    const ctx = canvas.getContext("2d");

    function drawLine(x1, y1, x2, y2, w, duration, callback) {
      const frames = 5; // Number of frames for the animation
      const deltaX = (x2 - x1) / frames;
      const deltaY = (y2 - y1) / frames;
      let frame = 0;

      function animate() {
        if (frame <= frames) {
          const currentX = x1 + deltaX * frame;
          const currentY = y1 + deltaY * frame;

          // Draw the current part of the line
          ctx.beginPath();
          ctx.moveTo(x1, y1);
          ctx.lineTo(currentX, currentY);
          ctx.strokeStyle = "black";
          ctx.lineWidth = w;
          ctx.stroke();

          frame++;
          setTimeout(animate, duration / frames); // Use setTimeout to control frame timing
        } else if (callback) {
          callback(); // Call the next step once the line is fully drawn
        }
      }

      animate();
    }

    // Function to animate the entire hut in sequence
    function animateHut() {
      drawLine(0, 66, 0, 26, 4, 100, () => {
        drawLine(0, 26, 26, 0, 2, 100, () => {
          drawLine(26, 0, 52, 26, 2, 100, () => {
            drawLine(52, 26, 78, 0, 2, 100, () => {
              drawLine(78, 0, 104, 26, 2, 100, () => {
                drawLine(104, 26, 104, 66, 4, 100, () => {
                  drawLine(26, 66, 26, 26, 2, 100, () => {
                    drawLine(26, 26, 52, 0, 2, 100, () => {
                      drawLine(52, 0, 78, 26, 2, 100, () => {
                        drawLine(78, 26, 78, 66, 2, 100, () => {
                          setTimeout(() => {
                            clearCanvas();
                            animateHut();
                          }, 500);
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });
    }

    // Clear the canvas to start drawing the hut again
    function clearCanvas() {
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clears the entire canvas
    }

    animateHut(); // Start the animation
  }, []);

  // Inline styles for the container
  const containerStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0,0,0,0.5)", // Semi-transparent background
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999,
  };

  return (
    <div style={containerStyle}>
      <div
        style={{
          backgroundColor: "white",
          height: "100px",
          width: "150px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <canvas id="myCanvas" width="104" height="66"></canvas>
      </div>
    </div>
  );
};

export default LoadingAnimation;
