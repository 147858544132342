import React from "react";
import arrow from "../../CommonIcons/itemArr.svg";
import { useApp } from "../../../Context/Context";
import addIcon from "./Images/addPlus.svg";
import "./roomsPage.css";
import AddRoomModal from "./AddRoomModal";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useState } from "react";
import RoomCard from "./RoomCard";
import upIm from "./Images/upIm.svg";
import axios from "axios";
import UploadImageModal from "./UploadImageModal";

const RoomsMain = () => {
  const { apartmentName } = useParams();
  const {
    setModalActive,
    apartments,
    fetchAllApartments,
    API_ENDPOINT,
    isAdmin,setAnimation,
    getAllottedApartments
  } = useApp();
  const [apartment, setApartment] = useState(null);
  const [rooms, setRooms] = useState([]);

  const fetchRoomsOfApartment = async () => {
    setAnimation(true)
    try {
      const response = await axios.get(
        `${API_ENDPOINT}/worker/apartments/${apartmentName}/rooms`
      );
      if (response.status === 200) setRooms(response.data.rooms);
    } catch (error) {
      console.error("Error fetching apartments:", error);
      return []; // Return an empty array in case of an error
    }
    setAnimation(false)
  };

  useEffect(() => {
    if (isAdmin) {
      fetchAllApartments();
    } else {
      getAllottedApartments();
    }
    fetchRoomsOfApartment();
    // eslint-disable-next-line
  }, [isAdmin]);

  useEffect(() => {
    let aprt = apartments.filter((aprt) => aprt.apartment_id === Number(apartmentName));
    if (aprt.length > 0) setApartment(aprt[0]);
    // eslint-disable-next-line
  }, [apartments]);

  return (
    <>
      <div className="majorDisplayMainDiv homeItemsMainDiv">
        <>
          {apartment && (
            <span style={{ display: "flex", justifyContent: "space-between" }}>
              <h2>
                {!isAdmin && "Select "}Rooms
                <img alt="arrow" src={arrow} />
              </h2>
              <div className="workerHeaderInfo">
                <ul>
                  <li>
                    Apartment Id - <span>{apartment.apartment_id}</span>
                  </li>
                  <li>
                    Apartment Name - <span>{apartment.name_of_property}</span>
                  </li>
                </ul>
              </div>
            </span>
          )}
          {!isAdmin && <div style={{ width: "100%", height: "100px" }}>
            <div className="uploadDamitemsBtn" 
            onClick={() => {
              setModalActive(
                <UploadImageModal
                apartmentId={apartment.apartment_id}
                />
              );
            }}>
              <img alt="ipImg" src={upIm} />
              Upload Damage Item
            </div>
          </div>}
          <div className="cardsContiner">
            {isAdmin && (
              <div
                className="addRoomCUstom addCards"
                onClick={() => {
                  setModalActive(
                    <AddRoomModal
                      apartment_id={apartment.apartment_id}
                      rooms={rooms}
                      setRooms={setRooms}
                    />
                  );
                }}
                style={{
                  maxHeight: "90px",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                }}
              >
                <img
                  alt="addIcon"
                  src={addIcon}
                  style={{
                    // margin: "0 20px 10px 0",
                    height: "20px",
                  }}
                />
                <p
                  className="addAprtClass"
                  style={{
                    fontSize: "20px",
                    lineHeight: "100%",
                    margin: "3px 0 0 0",
                    textAlign: "center",
                  }}
                >
                  Add Room{" "}
                </p>
              </div>
            )}
            <>
              {rooms &&
                rooms.map((room) => {
                  return <RoomCard key={room.room_id} room={room} />;
                })}
            </>
          </div>
        </>
      </div>
    </>
  );
};

export default RoomsMain;
