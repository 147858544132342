
import React, { useEffect, useState } from "react";
import { useApp } from "../../../Context/Context";
import { useNavigate, useParams } from "react-router-dom";
import SelectItemsCard from "../Worker/SelectItemsCard";
import axios from "axios";
import ItemCard from "../HomePage/ItemCard";

const ClenanerWorkFlow = () => {
  const { API_ENDPOINT, navigationStack, setHeaderNavigation } = useApp();
  // const [items, setItems] = useState([]);
  const [apartment, setApartment] = useState("");
  const [roomName, setRoomName] = useState("");
  let { roomId, apartmentName } = useParams();
  roomId = roomId.split("-")[1];
  const [items1, setItems1] = useState([]);
  const [items, setItems] = useState([]);
  const [state, setState] = useState({
    damageState: true,
    missingState: false,
    allotState: false,
    washState:false,
    collectState: false,
    checkList: false,
  });
  const navigate = useNavigate();
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    const flag = items1.some((item) => item.selected);
    setSelected(flag)
  }, [items1]);
  

  const fetchItems1 = async () => {
    const url = `${API_ENDPOINT}/worker/get_items_allotted_to_room?apartment_id=${apartmentName}&room_id=${roomId}`;

    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "User-id": JSON.parse(sessionStorage.getItem("user-data")).user_id,
        },
      });

      if (!response.ok) {
        const errData = await response.json();
        throw new Error(errData.error);
      }
      const data = await response.json();
      console.log(data.items);
      let allotedItems = data.items;
      // setItems(data.items);
      if (allotedItems) {
        let mappingItems = [];

        allotedItems.forEach((item) => {
          let item1 = {
            ...item,
            value: item.quantity_allotted,
            selected: false,
          };
          if (item1.quantity_allotted > 0) {
            mappingItems.push(item1);
          }
        });
        console.log(mappingItems);
        setItems1(mappingItems);
      }
      setApartment(data.apartment);
      setRoomName(data.room);
    } catch (err) {
      console.log(err); // Handle any errors
    }
  };

  const fetchWorkerAllotedItems = async function () {
    let userId = JSON.parse(sessionStorage.getItem("user-data")).user.user_id;
    try {
      if (!userId) {
        console.log("User Not Login");
      }
      const response = await axios.get(
        `${API_ENDPOINT}/worker/get_items_allotted_to_user`,
        {
          headers: {
            "User-id": userId, // Pass the user ID in the request header
          },
        }
      );
      let allotedItems = response.data.allotted_items;
      // setItems(data.items);
      if (allotedItems) {
        let mappingItems = [];

        allotedItems.forEach((item) => {
          let item1 = {
            ...item,
            value: item.quantity_allotted,
            selected: false,
          };
          if (item1.quantity > 0) {
            mappingItems.push(item1);
          }
        });
        console.log(mappingItems);
        setItems1(mappingItems);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        console.log("User not found.");
      } else {
        console.log("An error occurred while fetching the items.", err);
      }
    }
  };


  

  const handleCollect = async () => {
    let selectedItems = items1
      .map((item) => ({
        item_id: item.item_id,
        quantity: Number(item.value),
      }));
      if(selectedItems.length === 0){
        await  fetchWorkerAllotedItems()
        return;
      }

    const payload = {
      apartment_id: Number(apartmentName),
      room_id: Number(roomId),
      items: selectedItems,
    };
    let userId = JSON.parse(sessionStorage.getItem("user-data")).user.user_id;

    try {
      const response = await axios.post(
        `${API_ENDPOINT}/worker/collect_back_item`,
        payload,
        {
          headers: {
            "User-id": userId,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Success:", response.data);
      if (response.status === 200) {
        
      }
      fetchWorkerAllotedItems();
    } catch (error) {
      console.log("Error allotting items to worker:", error);
      
    }

    
  };

  


  

  const handleSubmit = async () => {
    navigateToApart();
  };

  const navigateToApart = async function () {
    navigate(navigationStack.generateLink(navigationStack.peek()));
  };

  useEffect(() => {
    fetchItems1();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="ClenanerWorkFlowMainDiv">
        <span style={{ display: "flex", justifyContent: "space-between" }}>
          <h2>Items For Wash </h2>
          <div className="workerHeaderInfo">
            <ul>
              <li>
                Apartment Name - <span>{apartment}</span>
              </li>
              <li>
                Room Name - <span>{roomName}</span>
              </li>
            </ul>
          </div>
        </span>
       
        <div className="itemsContiner">
          {
            
            
          (items1.map((item) => {
            return (
              <ItemCard
                editable={false}
                item={item}
                key={item.item_id}
              />
            );
          }))}
        </div>
        <div className="BtnConatiner">
          
         
         
            <button
              className="blueBtn"
              onClick={() => {
                handleCollect();
              }}
            >
              Collect 
            </button>
          
          
          
        </div>
      </div>
    </>
  );
};

export default ClenanerWorkFlow;
