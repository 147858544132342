import React, { useState } from "react";
import { useApp } from "../../../Context/Context";
import "../../commonCss/modal.css";
import upImg from "./Images/upBIm.svg";
import axios from "axios";

const UploadImageModal = ({ apartmentId }) => {
  // Define state for the inputs
  const { API_ENDPOINT } = useApp();
  // const [image, setImage] = useState(null); // State to hold the selected image

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("No file chosen");
  // Handle image file selection
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    } else {
      setFile(null);
      setFileName("No file chosen");
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    let userId = JSON.parse(sessionStorage.getItem("user-data")).user.user_id;
    console.log(userId)
    e.preventDefault();
    if (!userId) {
      console.log("User ID is required");
      return;
    }
    if (!apartmentId || !file) {
      console.log("Apartment ID and image are required");
      return;
    }

    const formData = new FormData();
    formData.append("apartment_id", apartmentId);
    formData.append("image_damaged_product", file);

    try {
      const response = await axios.post(
        `${API_ENDPOINT}/worker/upload_photo`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "User-id": userId,
          },
        }
      );

      console.log(response.data.message);
    } catch (err) {
      if (err.response) {
        console.log(err.response.data.error);
      } else {
        console.log("An unexpected error occurred");
      }
    }
  };

  return (
    <>
      <div className="modalContentInner">
        <h3>
          {" "}
          <img alt="UpImf" src={upImg} style={{ margin: "0 10px 0 0" }} />
          Upload Photo
        </h3>
        <div className="file-input-container file-input-container1">
          <input
            type="file"
            id="file"
            className="file-input"
            onChange={handleFileChange}
          />
          <label htmlFor="file" className=" file-label1">
            Select from device / Click Photo
          </label>
        </div>
        {/* <div className="file-input-container2">
          <input
            type="file"
            id="file"
            className="file-input"
            accept="image/*"
            capture="camera" // Forces camera access only
            onChange={handleFileChange}
          />
          Capture
        </div> */}

        <span className="file-name" style={{textAlign:"left"}}>{fileName}</span>

        <button className="modalBtn" onClick={handleSubmit}>
          Upload
        </button>
      </div>
    </>
  );
};

export default UploadImageModal;
