import "./App.css";
import {  Route,  Routes } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import { AppProvider } from "./Context/Context";
import Login from "./Components/Pages/Common/Login";
import "./Components/commonCss/modal.css"
import CompleteMainApp from "./Components/Pages/Common/CompleteMainApp";
import Alert from "./Components/Pages/Common/Alert";

function App() {


  return (
    <AppProvider>
      <div className="App">
      
      
        <Routes>
          <Route path="/" element={<Login/>}/>
          <Route path="/*" element={<CompleteMainApp/>}/>
        </Routes>
      </div>
    </AppProvider>
  );
}

export default App;
