import React, { useEffect, useState } from "react";
import { useApp } from "../../../Context/Context";

const AddApartmentModal = () => {
  // Define state for the inputs
  const {  setModalActive, fetchWorkers, workers,API_ENDPOINT,setApartments,apartments,setAnimation,showAlert } = useApp();
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("No file chosen");
  const [selectedWorker, setSelectedWorker] = useState(0); 


  const handleWorkerChange = (event) => {
    setSelectedWorker(event.target.value); // Update state when worker is selected
    console.log('Selected Worker ID:', event.target.value);
  };
  
  useEffect(() => {
    fetchWorkers();
    // eslint-disable-next-line
  }, []);

  // Handle file selection
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setFileName(selectedFile.name);
    } else {
      setFile(null);
      setFileName("No file chosen");
    }
  };

  // Handle form submission
  const handleSubmit =async () =>  {
    // Create a form data object if you want to send files as multipart/form-data
    setAnimation(true)
    const formData = new FormData();
    formData.append("name_of_property", name);
    formData.append("address", address);
    formData.append("apartment_photo", file);
    if(selectedWorker !== 0)
      formData.append("worker", selectedWorker);

    // Post the data (Here you can replace it with your API call logic)
    console.log({
      name,
      address,
      file,
    });

    try {
      // Make the POST request
      const response = await fetch(`${API_ENDPOINT}/owner/add_apartment`, {
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        const data = await response.json();
        setApartments([...apartments,data])
        showAlert("success", "Apartment Added Successfully !! ")
      } else {
        console.error('Error adding apartment:', response.statusText);
      }
    } catch (error) {
      showAlert("danger", "Apartment Not Added , Please try Again  !! ")
      console.error('Request failed:', error);
    }
    setAnimation(false)
    setModalActive(null);
  };

  return (
    <>
      <div className="modalContentInner">
        <h3>Add Apartment</h3>
        <div className="inputContainer">
          <input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
          <div className="file-input-container">
            <input
              type="file"
              id="file"
              className="file-input"
              onChange={handleFileChange}
            />
            <label htmlFor="file" className="file-label">
              Choose File
            </label>
            <span className="file-name">{fileName}</span>
          </div>
          <select className="modalSelect" value={selectedWorker} onChange={handleWorkerChange}>
          <option className="option" value={0}>Select Worker</option>
            {workers.map((worker)=>(
            <option key={worker.id} className="option" value={worker.id}>{worker.name}</option>))}
          </select>
        </div>
        <button className="modalBtn" onClick={handleSubmit}>
          Add
        </button>
      </div>
    </>
  );
};

export default AddApartmentModal;
