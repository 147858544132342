import React, { useEffect } from "react";
import arrow from "./Icons/itemArr.svg";
import addIcon from "./Icons/addIcon.svg";
import ItemCard from "./ItemCard";
import { useApp } from "../../../Context/Context";
import AddItemsModal from "./AddItemsModal";

const HomeItems = () => {
  const {  setModalActive, fetchItems, items } = useApp();

  useEffect(() => {
    fetchItems();
    // eslint-disable-next-line
  },[]);


  return (
    <>
      <div className="homeItemsMainDiv">
        <h2>
          Items <img alt="arrow" src={arrow} />
        </h2>
        <div className="itemsContiner">
          <div
            className="addItem"
            onClick={() => {
              setModalActive(<AddItemsModal />);
            }}
          >
            <img alt="addIcon" src={addIcon} style={{ margin: "0 0 10px 0" }} />
            <p>Add Items </p>
          </div>
          {items.map((item, index) => (
            <ItemCard
            item = {item}
              key={item.item_id} 
              editable={true}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default HomeItems;
