// stack.js

class Stack {
    constructor() {
      this.items = [];
    }
  
    push(element) {
      this.items.push(element);
    }
  
    pop() {
      if (this.isEmpty()) {
        return "Stack is empty";
      }
      return this.items.pop();
    }
  
    peek() {
      if (this.isEmpty()) {
        return "Stack is empty";
      }
      return this.items[this.items.length - 1];
    }
  
    isEmpty() {
      return this.items.length === 0;
    }
  
    size() {
      return this.items.length;
    }
  
    clear() {
      this.items = [];
    }

    generateLink(ele){
      let ind = this.items.indexOf(ele);
      let str = "";

      for(let i = 0 ; i <= ind ; i++){
        str += this.items[i];
      }
      return str
    }

    

    convertnav(ele){
      
    }

   
  }
  
  export default Stack;
  