import React from "react";
import "./common.css";
import logo from "./Icons/pavanLogo.svg";
import profile from "./Icons/pavanProfile.svg";
import HeaderNavigation from "./HeaderNavigation";
import { useApp } from "../../../Context/Context";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const {navigationStack,setHeaderNavigation} = useApp()
  const {location} = useLocation()
  const navigate = useNavigate();
  return (
    <>
      <div className="headerMainDiv">
        <div className="headerLogo">
          <img alt="logo" src={logo} />
        </div>
       
        <div className="otherLogo">
        <div id="google_translate_element"></div>
          <div onClick={()=>{
            
            navigate(`/home/profile`);
          }}>
             
            <img alt="profileLogo" src={profile}/>
          </div>
        </div>
      </div>
      <HeaderNavigation/>
    </>
  );
};

export default Header;
