import React from "react";
import editIcon from "../../CommonIcons/editICon.svg"
import imageLink from "./Icons/dummy.jpeg"
import { useApp } from "../../../Context/Context";
import { useLocation, useNavigate } from "react-router-dom";
import EditApartmentModal from "./EditApartmentModal";

const ApartmentCard = ({apartment}) => {

    const {
        
        setModalActive,MEDIA_ENDPOINT,isAdmin,setWorkerAlloted
      } = useApp();

      const navigate = useNavigate();
      const location = useLocation();

    const navigateToWorker = async function (worker) {
      console.log(apartment)
      setWorkerAlloted(apartment.worker)
        navigate(`${location.pathname}/${worker}`);
      };

    
  return (
    <>
      <div className="cardMainDIv">
        <img
          alt="workerPic"
          src={apartment.apartment_photo ? `${MEDIA_ENDPOINT}${apartment.apartment_photo}`:imageLink}
          onClick={() => navigateToWorker(apartment.apartment_id)}
        />
        <div className="cardDetails">
          <p>{apartment.name_of_property}</p>
          {isAdmin && <button
            onClick={() => {
              setModalActive(<EditApartmentModal apartment={apartment} />);
            }}
          >
            <img alt="editIcon" src={editIcon} />
          </button>}
        </div>
      </div>
    </>
  );
};

export default ApartmentCard;
